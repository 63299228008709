<template>
	<div class="wraper pagezclist">
		<el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false"
			:visible.sync="drawer" :with-header="false">
			<div class="drawer_box">
				<el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
					<el-button size="small" type="primary" plain icon="el-icon-search" @click="onSearch">查询
					</el-button>
					<el-button size="small" type="primary" plain @click="onReset">重置
					</el-button>
				</el-row>
				<el-divider style="width: 100%"></el-divider>
				<el-form :model="queryForm" ref="queryForm" label-width="10px">
					<el-row>分字段搜索</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="">
								<el-input v-model="queryForm.zc_code" size="mini" placeholder="资产编码"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-input v-model="queryForm.title" size="mini" placeholder="资产名称"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-select v-model="queryForm.zc_gztype" size="mini" placeholder="购置方式">
									<el-option label="外购" value="外购"></el-option>
									<el-option label="租赁" value="租赁"></el-option>
									<el-option label="借用" value="借用"></el-option>
									<el-option label="自行建造" value="自行建造"></el-option>
									<el-option label="其他方式" value="其他方式"></el-option>
								</el-select>
								<!--								<el-input v-model="queryForm.zc_gztype" size="mini" placeholder="购置方式"></el-input>-->
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-input v-model="queryForm.pinpai" size="mini" placeholder="品牌"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-input v-model="queryForm.xinghao" size="mini" placeholder="型号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-input v-model="queryForm.jldw" size="mini" placeholder="计量单位"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" class="labelCss">
							<el-form-item label="价值区间:" label-width="80px">
								<div class="flexStart">
									<el-input v-model="queryForm.jine1" size="mini" placeholder="最小值"></el-input>
									<span style="margin: 0 10px;">-</span>
									<el-input v-model="queryForm.jine2" size="mini" placeholder="最大值"></el-input>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>筛选</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="">
								<el-cascader ref="myCascader1" v-model="queryForm.zc_typeid" :options="typeList"
									size="mini" :props="optionProps1" :show-all-levels="true" filterable
									placeholder="资产分类" @change="handleCascaderChange1"></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-cascader ref="myCascader" v-model="queryForm.zc_storeid" :options="areaList"
									size="mini" :props="optionProps" :show-all-levels="true" filterable
									placeholder="所在位置" @change="handleCascaderChange"></el-cascader>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="">
								<el-date-picker v-model="queryForm.zc_date" type="date" size="mini"
									value-format="yyyy-MM-dd" placeholder="购置/借用日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-select v-model="queryForm.status" size="mini" placeholder="状态">
									<el-option label="闲置" :value="0"></el-option>
									<el-option label="领用" :value="1"></el-option>
									<el-option label="派发" :value="2"></el-option>
									<el-option label="借用" :value="3"></el-option>
									<el-option label="租赁已退还" :value="4"></el-option>
									<el-option label="维修中" :value="5"></el-option>
									<el-option label="报废" :value="-1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-select v-model="queryForm.store_gly_id" size="mini" :disabled="disabled"
									placeholder="仓管">
									<el-option label="请选择" value=""></el-option>
									<el-option :label="item.name" :value="item.union_id"
										v-for="(item,idx) in storeAdmin" :key="idx"></el-option>


								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</el-drawer>
		<div id="printDiv" v-if="showPrinter">ahahahahahha</div>
		<el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
			<div class="flex_1">
				<el-button size="small" type="primary" plain icon="el-icon-plus" @click="add">新增
				</el-button>
				<!-- <el-dropdown split-button trigger="click" type="primary" plain size="small" @command="handleCommand" class="cus_dropdown"
				 style="margin-left: 10px;">
					操作
					<el-dropdown-menu slot="dropdown">
						
						<el-dropdown-item :command="3" divided>下载模板</el-dropdown-item>
						<el-dropdown-item :command="0" divided>导入资产</el-dropdown-item>
						<el-dropdown-item :command="1" divided>借出</el-dropdown-item>
						<el-dropdown-item v-if="false" :command="2" divided>借出归还</el-dropdown-item>
						<el-dropdown-item :command="3" divided>派发</el-dropdown-item> 
					</el-dropdown-menu>
				</el-dropdown> -->
				<el-button style="margin-left: 10px;" size="small" type="primary" plain icon="el-icon-full-screen"
					@click="createQrcode">一键生成二维码
				</el-button>
				<el-dropdown split-button trigger="click" type="primary" plain size="small" class="cus_dropdown"
					style="margin-left: 10px;"><i class="el-icon-printer" style="margin-right: 5px;"></i>打印
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item divided>
							<div @click="printZcList">打印标签</div>
						</el-dropdown-item>
						<!--                        <el-dropdown-item divided><div @click="print_ruku" v-print="printObj">打印入库单</div></el-dropdown-item>-->
						<el-dropdown-item divided>
							<div @click="print_ruku">打印入库单</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-button style="margin-left: 10px;" size="small" type="danger" plain icon="el-icon-delete"
					@click="delSlct">删除
				</el-button>
				<el-link type="primary" :href="'./static/zichan_temp.xls'" style="margin-left: 20px;" target="_blank">
					下载模板</el-link>

				<el-link type="primary" @click="uploadXls" style="margin-left: 20px;" target="_blank">导入模板</el-link>

			</div>
			<div>
				<el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;"
					placeholder="资产名称">
					<el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
				</el-input>
				<el-button type="text" @click="drawer=true" style="color: #999999;margin-left: 10px;">
					<i class="el-icon-s-operation"></i>&nbsp;高级搜索
				</el-button>
			</div>
		</el-row>
		<el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange"
			style="width: 100%;" stripe border size="small">
			<el-table-column type="index" label="编号" width="50">
			</el-table-column>
			<el-table-column type="selection" width="55"></el-table-column>

			<el-table-column prop="status" label="资产状态" width="80">
				<template slot-scope="scope">
					<span v-if="scope.row.STATUS==0" class="st0">闲置</span>
					<span v-if="scope.row.STATUS==1" class="st1">领用</span>
					<span v-if="scope.row.STATUS==2" class="st2">派发</span>
					<span v-if="scope.row.STATUS==3" class="st3">借用</span>
					<span v-if="scope.row.STATUS==4" class="st4">租赁已退还</span>
					<span v-if="scope.row.STATUS==5" class="st5">维修中</span>
					<span v-if="scope.row.STATUS==-1" class="st-1">报废</span>
				</template>
			</el-table-column>
			<el-table-column prop="zc_code" label="二维码" width="70">
				<template slot-scope="scope">
					<img onclick="viewImage(this)" :src="scope.row.qrcode1" alt="" v-if="scope.row.qrcode1"
						style="width: 40px; vertical-align: top;">
				</template>
			</el-table-column>
			<el-table-column prop="zc_code" label="资产编码(点击查看详情)" width="180">
				<template slot-scope="scope">
					<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.zc_code}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="title" label="资产名称" min-width="120"></el-table-column>
			<el-table-column prop="zc_typeparent" label="资产分类" width="160">
				<template slot-scope="scope">
					{{scope.row.zc_typeparent}} {{scope.row.typename}}
				</template>
			</el-table-column>
			<el-table-column prop="last_location" label="所在位置" min-width="160">
				<template slot-scope="scope">
					<div @click="showStore(scope.row)" style="color: #409EFF; cursor: pointer;">
						<div v-if="scope.row.last_location">
							{{scope.row.last_location}}
						</div>
						<div v-else>
							<span v-if="scope.row.storename3">{{scope.row.storename3}}</span>
							<span v-if="scope.row.storename2">{{scope.row.storename2}}</span>
							<span v-if="scope.row.storename1">{{scope.row.storename1}}</span>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="pinpai" label="品牌" width="120"></el-table-column>
			<el-table-column prop="xinghao" label="型号" width="120" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="jine" label="价格" width="100" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span>￥{{scope.row.jine}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="last_username" label="使用人" width="100" :show-overflow-tooltip="true">
			</el-table-column>
			<el-table-column prop="store_gly_name" label="仓管" width="100" :show-overflow-tooltip="true">
			</el-table-column>
			<el-table-column label="操作" width="120" fixed="right">
				<template slot-scope="scope">
					<el-tag size="mini" @click="editItem(scope.row)"><i class="el-icon-edit"></i></el-tag>
					<el-tag type="danger" size="mini" style="margin-left: 10px;" @click="removeItem(scope.row)"><i
							class="el-icon-delete"></i></el-tag>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
			:current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.pre_page"
			layout="total,sizes, prev, pager, next" :total="page.count">
		</el-pagination>
		<!--新增资产-->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="1000px" custom-class="width_800 cus_dialog"
			:close-on-click-modal="false">
			<el-form :model="EditItem" :rules="rules" ref="addzc" label-width="120px">
				<div v-if="disabled&&(EditItem.status==1||EditItem.status==2||EditItem.status==3)">
					<div class="titleBar flexStart">
						<div class="div flexStart">{{statusInfo.title}}</div>
					</div>
					<!--                    status：1领用；2派发；3借用-->
					<el-row class="statusInfo-box">
						<el-form-item :label="EditItem.status==3?'借用人：':'领用人：'" label-width="102px">
							<!--                                last_username最后使用人-->
							{{EditItem.last_username}}
						</el-form-item>
						<el-form-item :label="EditItem.status==3?'借用日期：':'领用日期：'" label-width="102px">
							{{EditItem.status==3?statusInfo.startdate:statusInfo.cdate}}
						</el-form-item>
						<el-form-item v-if="EditItem.status==3" label="预计归还日期:" label-width="102px">
							{{statusInfo.enddate}}
						</el-form-item>
						<el-form-item :label="EditItem.status==3?'借用后位置：':'领用后位置：'" label-width="102px">
							{{statusInfo.zc_where}}
						</el-form-item>
						<el-form-item :label="EditItem.status==3?'借用描述：':'领用描述：'" label-width="102px">
							{{statusInfo.czdesc}}
						</el-form-item>
					</el-row>
				</div>
				<div class="titleBar flexStart">
					<div class="div flexStart">资产信息</div>
				</div>
				<el-row style="margin-top: 10px;">
					<el-col :span="12">
						<el-form-item label="资产编码:">
							<el-input v-model="EditItem.zc_code" size="mini" disabled placeholder="资产编码将由系统自动生成">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="资产名称:" prop="title">
							<el-input v-model="EditItem.title" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12" class="flexStart">
						<div style="width: 120px!important;height: 40px;line-height: 40px;padding-right: 12px;box-sizing: border-box;"
							class="flexEnd">
							<span style="color: #F56C6C;margin-right: 4px;">*</span>
							<span>资产分类:</span>
						</div>
						<el-cascader ref="myCascader1" v-model="EditItem.zc_typeid" :options="typeList" size="mini"
							:disabled="disabled" :props="optionProps1" :show-all-levels="true" filterable
							placeholder="请选择" @change="handleCascaderChange1" class="flex_1"></el-cascader>
					</el-col>
					<el-col :span="12">
						<el-form-item label="计量单位:" prop="jldw">
							<el-input v-model="EditItem.jldw" size="mini" disabled placeholder="请选择分类"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12" class="flexStart">
						<div style="width: 120px!important;height: 40px;line-height: 40px;padding-right: 12px;box-sizing: border-box;"
							class="flexEnd">
							<span style="color: #F56C6C;margin-right: 4px;">*</span>
							<span>所在位置:</span>
						</div>
						<el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
							:disabled="disabled" :props="optionProps" :show-all-levels="true" filterable
							placeholder="请选择" @change="handleCascaderChange" class="flex_1"></el-cascader>
					</el-col>
					<el-col :span="12">
						<el-form-item label="购置方式:" prop="zc_gztype">
							<el-select v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="请选择">
								<el-option label="外购" value="外购"></el-option>
								<el-option label="租赁" value="租赁"></el-option>
								<el-option label="借用" value="借用"></el-option>
								<el-option label="自行建造" value="自行建造"></el-option>
								<el-option label="其他方式" value="其他方式"></el-option>
							</el-select>
							<!--							<el-input v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="资产购置方式，采购，租赁，借用等"></el-input>-->
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="购置/借用日期:" prop="zc_date">
							<el-date-picker v-model="EditItem.zc_date" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="品牌:" prop="pinpai">
							<el-input v-model="EditItem.pinpai" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="型号:" prop="xinghao">
							<el-input v-model="EditItem.xinghao" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-for="(zd,idx) in EditItem.zdjson" :key="idx">
						<el-form-item :label="zd.name+':'">
							<el-input v-if="!zd.type||zd.type=='string'" size="small" v-model="zd.value"
								:disabled="disabled" placeholder="请输入"></el-input>
							<el-input-number v-if="zd.type=='number'" v-model="zd.value" size="small"
								:disabled="disabled" controls-position="right" placeholder="请输入"></el-input-number>
							<el-date-picker v-if="zd.type=='date'" v-model="zd.value" style="width: 100%" type="date"
								size="small" :disabled="disabled" value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="价值金额:" prop="jine">
							<el-input v-model="EditItem.jine" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="状态:">
							<el-select v-model="EditItem.status" size="mini" disabled placeholder="请选择">
								<el-option label="闲置" :value="0"></el-option>
								<el-option label="维修中" :value="5"></el-option>
								<el-option label="报废" :value="-1"></el-option>

								<el-option label="领用" :value="1"></el-option>
								<el-option label="派发" :value="2"></el-option>
								<el-option label="借用" :value="3"></el-option>
								<el-option label="租赁已退还" :value="4"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="仓库管理员:">
							<el-select v-model="EditItem.store_gly_id" size="mini" :disabled="disabled"
								placeholder="请选择">
								<el-option label="请选择" value=""></el-option>
								<el-option :label="item.name" :value="item.union_id" v-for="(item,idx) in storeAdmin"
									:key="idx"></el-option>


							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="!EditItem.id">
						<el-form-item label="数量:">
							<el-input-number v-model="EditItem.num" size="mini" :disabled="disabled"
								controls-position="right" placeholder="请输入"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="资产描述说明:" prop="zcdesc">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zcdesc" size="mini"
								:disabled="disabled" placeholder="资产描述说明"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="上传图片:" v-show="!disabled">
							<el-upload action="" :http-request="uploadOss" :on-success="uploadCover"
								:on-preview="uploadPreview" :on-remove="handleRemove" :file-list="files" name="image">
								<el-button size="mini" plain icon="el-icon-upload2" type="primary"
									style="margin-left: 10px;">点击上传
								</el-button>
							</el-upload>
						</el-form-item>
						<el-form-item label="上传图片:" v-show="disabled&&files&&files.length>0">
							<!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
							<div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
								<img :src="f.src" onclick="viewImage(this)" style="opacity: 0;" />
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-divider content-position="left">
					<el-button type="text" size="small" @click="addCusField">字段不够?点此添加</el-button>
				</el-divider>
				<div class="titleBar flexStart">
					<div class="div flexStart">维保信息</div>
				</div>
				<el-row style="margin-top: 10px;">
					<el-col :span="12">
						<el-form-item label="维保供应商:" prop="wb_gxy">
							<el-input v-model="EditItem.wb_gxy" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="维保联系人:" prop="wb_lxr">
							<el-input v-model="EditItem.wb_lxr" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="维保电话:" prop="wb_phone">
							<el-input v-model="EditItem.wb_phone" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="维保到期时间:" prop="wb_deadline">
							<el-date-picker v-model="EditItem.wb_deadline" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="维保说明:" prop="wb_desc">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.wb_desc" size="mini"
								:disabled="disabled" placeholder="维保说明"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<div v-if="disabled" class="titleBar flexStart">
					<div class="div flexStart">操作记录</div>
				</div>
				<el-row v-if="disabled" style="margin-top: 20px;">
					<el-table :data="logList" border style="width: 100%">
						<el-table-column prop="opdate" label="操作时间" width="180">
							<template slot-scope="scope">
								{{scope.row.opdate||'-'}}</template>
						</el-table-column>
						<el-table-column prop="create_uid" label="操作人" width="180">
							<template slot-scope="scope">
								{{scope.row.create_uid||'-'}}</template>
						</el-table-column>
						<el-table-column prop="status" label="当前状态" width="80">
							<template slot-scope="scope">
								<div v-if="scope.row.usetype!=2">
									<span v-if="scope.row.sp_status==0&&scope.row.status==0" class="st0">未审核</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==1" class="st1">审核通过待签收</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==2" class="st2">已签收待归还</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==3" class="st3">归还申请中</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==4" class="st4">归还审核不通过</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==5" class="st5">已归还</span>
									<span v-if="scope.row.sp_status==2" class="st6">审核不通过</span>
								</div>
								<div v-else>
									<span v-if="scope.row.sp_status==1&&scope.row.status==0" class="st1">待签收</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==1" class="st2">使用中</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==2" class="st3">归还申请中</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==3" class="st4">归还审核不通过</span>
									<span v-if="scope.row.sp_status==1&&scope.row.status==4" class="st6">已归还</span>

								</div>
							</template>
						</el-table-column>
						<el-table-column prop="czdesc" label="操作内容">
							<template slot-scope="scope">
								{{scope.row.czdesc||'-'}}</template>
						</el-table-column>
						<el-table-column prop="handsign" label="签字图片">
							<template slot-scope="scope">
								<div v-if="scope.row.handsign" class="updimage"
									:style="{backgroundImage:'url('+scope.row.handsign+')'}"
									style="width: 66px;height: 66px;margin: 0;">
									<img :src="scope.row.handsign" onclick="viewImage(this)"
										style="opacity: 0;display: inline-block;width: 100%;height: 100%;" />
								</div>
								<div v-else>-</div>
							</template>
						</el-table-column>
						<el-table-column v-if="false" prop="remark" label="备注">
							<template slot-scope="scope">
								{{scope.row.remark||'-'}}
							</template>
						</el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button v-if="!disabled" type="primary" @click="saveForm('addzc')">保存</el-button>

				<el-button v-if="disabled" type="primary" @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>
		<!--        借出单-->
		<el-dialog title="借出单" :visible.sync="dialogVisible_borrow" width="1000px" custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-col :span="8">
						<el-form-item v-if="false" label="借用人:">
							<el-input v-model="EditItem.borrower_name" disabled placeholder="请选择">
								<el-button slot="append" @click="showUserDialog=true">选择</el-button>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="借用日期:">
							<el-date-picker v-model="EditItem.start_date" type="date" size="mini"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="预计归还日期:">
							<el-date-picker v-model="EditItem.end_date" type="date" size="mini"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="false">
						<el-form-item label="使用部门:">
							<el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
								:props="optionProps" :show-all-levels="true" filterable placeholder="请选择"
								@change="handleCascaderChange"></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="借用后位置:">
							<el-cascader ref="myCascader2" v-model="EditItem.zc_whereid" :options="areaList" size="mini"
								:disabled="disabled" :props="optionProps" :show-all-levels="true" filterable
								placeholder="请选择" @change="handleCascaderChange2"></el-cascader>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="借用备注:">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zc_desc" size="mini"
								placeholder="借用备注"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-divider style="width: 100%"></el-divider>

				<el-row style="margin-top: 20px;">
					<el-table :data="multipleSelection" border style="width: 100%" size="small">
						<el-table-column label="序号" type="index" width="55"></el-table-column>

						<el-table-column prop="zc_code" label="资产编码" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="title" label="资产名称"></el-table-column>
						<el-table-column prop="zc_typename" label="资产分类"></el-table-column>
						<el-table-column prop="storename" label="所在位置" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="pinpai" label="品牌"></el-table-column>
						<el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveBorrow">保存</el-button>
			</div>
		</el-dialog>
		<!--        借出归还单-->
		<el-dialog title="借出归还单" :visible.sync="dialogVisible_return" width="1000px"
			custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-col :span="8">
						<el-form-item label="归还日期:">
							<el-date-picker v-model="EditItem.end_date" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="归还后位置:">
							<el-input v-model="EditItem.zc_where" size="mini" :disabled="disabled" placeholder="请输入">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="归还处理人:">
							<el-input v-model="EditItem.return_name" disabled placeholder="请选择"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="借用日期:">
							<el-date-picker v-model="EditItem.start_date" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>

				</el-row>
				<el-row>
					<el-col :span="8" v-if="false">
						<el-form-item label="使用部门:">
							<el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
								:disabled="disabled" :props="optionProps" :show-all-levels="true" filterable
								placeholder="请选择" @change="handleCascaderChange"></el-cascader>
						</el-form-item>
					</el-col>


					<el-col :span="24">
						<el-form-item label="归还备注:">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.remark" size="mini"
								:disabled="disabled" placeholder="备注"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-divider style="width: 100%"></el-divider>

				<el-row style="margin-top: 20px;">
					<el-table :data="multipleSelection" border style="width: 100%">
						<el-table-column label="序号" type="index" width="55"></el-table-column>

						<el-table-column prop="zc_code" label="资产编码">
							<template slot-scope="scope">
								<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">
									{{scope.row.zc_code}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="资产名称"></el-table-column>
						<el-table-column prop="zc_typename" label="资产分类"></el-table-column>
						<el-table-column prop="storename" label="所在位置">

						</el-table-column>
						<el-table-column prop="pinpai" label="品牌"></el-table-column>
						<el-table-column prop="xinghao" label="型号"></el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveBorrow">保存</el-button>
			</div>
		</el-dialog>
		<!--        派发单-->
		<el-dialog title="派发单" :visible.sync="dialogVisible_distribute" width="1000px"
			custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-col :span="8">
						<el-form-item label="领用人:">
							<div class="flexStart">
								<el-input v-model="EditItem.borrower_name" size="mini" disabled type="primary" plain
									placeholder="请选择">
								</el-input>
								<el-button size="mini" type="primary" plain @click="showUserDialog=true">选择</el-button>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="领用日期:">
							<el-date-picker v-model="EditItem.start_date" type="date" size="mini"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="领用后位置:">
							<el-cascader ref="myCascader2" v-model="EditItem.zc_whereid" :options="areaList" size="mini"
								:props="optionProps" :show-all-levels="true" filterable placeholder="请选择"
								@change="handleCascaderChange2"></el-cascader>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="内容描述:">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zc_desc" size="mini"
								placeholder="备注"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-divider style="width: 100%"></el-divider>

				<el-row style="margin-top: 20px;">
					<el-table :data="multipleSelection" border style="width: 100%" size="small">
						<el-table-column label="序号" type="index" width="55"></el-table-column>

						<el-table-column prop="zc_code" label="资产编码" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="title" label="资产名称"></el-table-column>
						<el-table-column prop="zc_typeparent" label="资产分类">
							<template slot-scope="scope">
								{{scope.row.zc_typeparent}} {{scope.row.typename}}
							</template>
						</el-table-column>
						<el-table-column prop="storename" label="所在位置" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="pinpai" label="品牌"></el-table-column>
						<el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveDistribute">保存</el-button>
			</div>
		</el-dialog>
		<!--        派发退库单-->
		<el-dialog title="派发退库单" :visible.sync="dialogVisible_withdraw" width="1000px"
			custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-col :span="8">
						<el-form-item v-if="false" label="借用人:">
							<el-input v-model="EditItem.borrower_name" disabled placeholder="请选择">
								<el-button slot="append" @click="showUserDialog=true">选择</el-button>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="借用日期:">
							<el-date-picker v-model="EditItem.start_date" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="预计归还日期:">
							<el-date-picker v-model="EditItem.end_date" type="date" size="mini" :disabled="disabled"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8" v-if="false">
						<el-form-item label="使用部门:">
							<el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
								:disabled="disabled" :props="optionProps" :show-all-levels="true" filterable
								placeholder="请选择" @change="handleCascaderChange"></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="借用后位置:">
							<el-input v-model="EditItem.zc_where" size="mini" :disabled="disabled"
								placeholder="资产购置方式，采购，租赁，借用等"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="借用备注:">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.remark" size="mini"
								:disabled="disabled" placeholder="备注"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-divider style="width: 100%"></el-divider>

				<el-row style="margin-top: 20px;">
					<el-table :data="multipleSelection" border style="width: 100%">
						<el-table-column label="序号" type="index" width="55"></el-table-column>

						<el-table-column prop="zc_code" label="资产编码">
							<template slot-scope="scope">
								<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">
									{{scope.row.zc_code}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="资产名称"></el-table-column>
						<el-table-column prop="zc_typename" label="资产分类"></el-table-column>
						<el-table-column prop="storename" label="所在位置"></el-table-column>
						<el-table-column prop="pinpai" label="品牌"></el-table-column>
						<el-table-column prop="xinghao" label="型号"></el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveBorrow">保存</el-button>
			</div>
		</el-dialog>
		<!-- <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser> -->
		<!--       新增字段-->
		<el-dialog title="自定义字段" :visible.sync="showCusField" width="600px" custom-class="width_800 cus_dialog">


			<el-form :model="FieldItem" ref="FieldItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-form-item label="所属类型:">
						<el-input v-model="FieldItem.value" disabled></el-input>
					</el-form-item>

					<el-form-item label="设备名称:">
						<el-input v-model="FieldItem.cname" disabled></el-input>
					</el-form-item>
					<el-form-item label="英文编码:">
						<el-input v-model="FieldItem.ename" disabled></el-input>
					</el-form-item>
					<el-divider style="width: 100%"></el-divider>
					<div style="color: #409EFF;margin: 10px 0 10px 0;">自定义字段</div>
					<el-row v-for="(c,idx) in FieldItem.zdjson" :key="idx" class="flexStart drawer_box">
						<el-col :span="11">
							<el-form-item label="字段名称:" label-width="80px">
								<el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="9" style="margin-left: 10px;">
							<el-form-item label="字段类型" label-width="80px">
								<el-select size="small" v-model="c.type" placeholder="请选择">
									<el-option value="string" label="文本"></el-option>
									<el-option value="number" label="数值"></el-option>
									<el-option value="date" label="日期"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i
								class="el-icon-remove"></i></el-tag>
					</el-row>
					<el-row style="margin: 20px 0">
						<el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus"
							circle></el-button>
					</el-row>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveCusField">保存</el-button>
			</div>
		</el-dialog>


		<biaoqian v-if="showBiaoqian" :zclist="bqZcList"></biaoqian>
		<div style="display: none;">
			<el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch"
				:show-file-list="false" name="image">

				<el-button size="mini" id="addXlsbtn">+</el-button>
			</el-upload>
		</div>


		<el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
			<div style="padding-top: 10px;" v-for="item in ErrLog">{{item}}</div>
		</el-dialog>

		<StoreZc :storeid="storeid" :title="storename" v-if="showStorewin"></StoreZc>

	</div>
</template>

<script>
	// import Pagination from '../../com/pagination.vue'
	// import Deptuser from '../../com/deptuser.vue'
	import biaoqian from './biaoqian.vue'
	import StoreZc from './tmplt/store_zc.vue'
	export default {
		components: {
			// Pagination,
			// Deptuser,
			biaoqian,
			StoreZc
		},
		data() {
			return {
				DataList: [],
				multipleSelection: [],
				queryForm: {},
				page: {
					current_page: 1,
					pre_page: 20,
					count: 0,
					pageSizes: [10, 20, 50, 100]
				},
				dialogTitle: '新增资产',
				dialogVisible: false,
				EditItem: {
					status: 0,
					store_gly_id: '',
					store_gly_name: ''
				},
				disabled: false,
				typeList: [],
				areaList: [],
				storeAdmin: [],
				optionProps: {
					value: 'id',
					label: 'storename',
					emitPath: false,
					checkStrictly: true
				},
				optionProps1: {
					value: 'id',
					label: 'cname',
					emitPath: false
				},
				files: [],
				logList: [],
				dialogVisible_borrow: false,
				userinfo: {},
				temp_list: [], //todo 未用备用
				showUserDialog: false,
				dialogVisible_return: false,
				dialogVisible_distribute: false,
				dialogVisible_withdraw: false,
				drawer: false, //高级搜索面板
				showCusField: false,
				FieldItem: {},
				curDate: '',
				printObj: {
					id: "#printDiv",
					popTitle: "",
					extraCss: "",
					extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
				},
				showPrinter: false,
				showBiaoqian: false,
				bqZcList: [],
				statusInfo: {},
				dialogErrorVisible: false,
				ErrLog: [],
				storeid: "",
				storename: "",
				showStorewin: false,
				rules: {
					title: [{
						required: true,
						message: '请输入',
					}, ],
					zc_typeid: [{
						type: 'array',
						required: true,
						message: '请选择',
					}],
					zc_storeid: [{
						type: 'array',
						required: true,
						message: '请选择',
					}],
					zc_gztype: [{
						required: true,
						message: '请选择',
					}],
					zc_date: [{
						required: true,
						message: '请选择日期',
					}],
					pinpai: [{
						required: true,
						message: '请输入',
					}, ],
					xinghao: [{
						required: true,
						message: '请输入',
					}, ],
					jine: [{
						required: true,
						message: '请输入',
					}, ],
					zcdesc: [{
						required: true,
						message: '请输入',
					}, ]
				},
			}
		},
		mounted() {
			this.getList();
			this.getType();
			this.getArea();
			this.getCurDate();
			this.getStoreAdmins()
			let _this = this
			if (localStorage.getItem("user")) {
				_this.userinfo = JSON.parse(localStorage.getItem("user"))
				// console.log(_this.userinfo)
			}


		},
		methods: {
			getStoreAdmins() {
				this.$http.post("/api/zc_cg_list").then(res => {
					this.storeAdmin = res.data
				})
			},
			onSearch() {
				this.page.current_page = 1
				this.getList()
			},
			onReset() {
				this.queryForm = {}
				this.page.current_page = 1
				this.getList()
			},
			handleCommand(command) {
				switch (command) {
					case 1:
						if (this.multipleSelection.length == 0) {
							this.$message.warning('请选择空闲状态的资产');
						} else {
							if (!this.dep_Arr(this.multipleSelection)) {
								this.$message.warning('请选择空闲状态的资产');
							} else {
								this.dialogVisible_borrow = true
								this.EditItem = {
									start_date: this.curDate,
									end_date: this.curDate,
								}
								this.disabled = false
							}
						}
						break;
					case 2:
						if (this.multipleSelection.length == 0) {
							this.$message.warning('请选择借用状态的资产');
						} else {
							if (!this.dep_Arr(this.multipleSelection)) {
								this.$message.warning('请选择借用状态的资产');
							} else {
								this.dialogVisible_return = true
								this.EditItem.return_name = this.userinfo.name
								this.EditItem = {}
								this.disabled = false
							}
						}
						break;
					case 3:
						if (this.multipleSelection.length == 0) {
							this.$message.warning('请选择空闲状态的资产');
						} else {
							if (!this.dep_Arr(this.multipleSelection)) {
								this.$message.warning('请选择空闲状态的资产');
							} else {
								this.dialogVisible_distribute = true
								this.EditItem = {
									start_date: this.curDate
								}
								this.disabled = false
							}
						}
						break;
					case 4:
						if (this.multipleSelection.length == 0) {
							this.$message.warning('请选择派发状态的资产');
						} else {
							if (!this.dep_Arr(this.multipleSelection)) {
								this.$message.warning('请选择派发状态的资产');
							} else {
								this.dialogVisible_withdraw = true
								this.EditItem = {}
								this.disabled = false
							}
						}
						break;
					case 0:
						document.getElementById("addXlsbtn").click()
						break;
				}

			},
			dep_Arr(arr) {
				let a = true
				let fun = function(b) {
					b.map(s => {
						if (s.status != 0) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},
			//借出单
			saveBorrow() {
				let _this = this
				let params = _this.EditItem
				params.usetype = 1
				let arr = []
				params.files = JSON.stringify(arr)
				let ids = [],
					titles = [],
					titleStr = ''
				if (_this.multipleSelection.length > 0) {
					_this.multipleSelection.map(s => {
						ids.push(s.id)
						titleStr = `【${_this.userinfo.dept_name}】 ${_this.userinfo.name}申请借用 ${s.title}`
						titles.push(titleStr)
					})
					params.zc_id = ids.join(',')
					params.title = titles.join(',')
					_this.$http.post('/api/zc_use', params).then(res => {
						_this.dialogVisible_borrow = false;
						_this.$message({
							type: 'success',
							message: '保存成功'
						});
						_this.getList()
					})
				}

			},
			//借出归还单
			saveReturn() {
				let _this = this
				let params = _this.EditItem
				params.usetype = 1
				let arr = []
				params.files = JSON.stringify(arr)
				if (_this.multipleSelection.length > 0) {
					_this.multipleSelection.map(s => {
						setTimeout(function() {
							params.zc_id = s.id
							params.title =
								`【${_this.userinfo.dept_name}】 ${_this.userinfo.name}申请借用${s.title}`
							_this.$http.post('/api/zc_use', params).then(res => {
								_this.dialogVisible_borrow = false;
								_this.$message({
									type: 'success',
									message: '保存成功'
								});
								_this.getList()
							})
						}, 200)
					})
				}

			},
			// 派发单
			saveDistribute() {
				let _this = this
				let params = _this.EditItem
				params.usetype = 2
				let arr = []
				params.files = JSON.stringify(arr)
				if (!params.borrower_name) {
					_this.$message.warning('请选择领用人')
					return;
				}
				let ids = [],
					titles = []
				if (_this.multipleSelection.length > 0) {
					_this.multipleSelection.map(s => {
						ids.push(s.id)
						titles.push(s.title)
					})
					params.zc_id = ids.join(',')
					params.title = `${titles.join(',')}派发给${params.borrower_name}`
					_this.$http.post('/api/zc_paifa', params).then(res => {
						_this.dialogVisible_distribute = false;
						_this.$message.success('保存成功');
						_this.getList()
					})
				} else {
					_this.$message.warning('请选择资产！')
					return
				}
			},
			//派发退库单
			saveWithdraw() {
				let _this = this
				let params = _this.EditItem
				params.usetype = 1
				let arr = []
				params.files = JSON.stringify(arr)
				if (_this.multipleSelection.length > 0) {
					_this.multipleSelection.map(s => {
						setTimeout(function() {
							params.zc_id = s.id
							params.title =
								`【${_this.userinfo.dept_name}】 ${_this.userinfo.name}申请借用${s.title}`
							_this.$http.post('/api/zc_use', params).then(res => {
								_this.dialogVisible_borrow = false;
								_this.$message({
									type: 'success',
									message: '保存成功'
								});
								_this.getList()
							})
						}, 200)
					})
				}

			},
			uploadCover(e) {
				this.files.push({
					filename: e.fileName,
					src: e.src,
					name: e.fileName,
					url: e.src
				})
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.files = fileList
			},
			uploadPreview(file) {
				// console.log(879,file)
				window.open(file.src || file.url)
			},
			getType() {
				let _this = this
				this.$http.post("/api/zc_type", {
					type: 'zc'
				}).then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.typeList = result
					})
				})
			},
			getArea() {
				let _this = this
				this.$http.post("/api/zc_store").then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.areaList = result
					})
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise(resolve => {
					function traversal(data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},
			getList() {
				let _this = this
				let params = {},
					arr = []
				params.page = _this.page.current_page
				params.pagesize = _this.page.pre_page
				if (_this.queryForm != {}) {
					for (let key in _this.queryForm) {
						if (_this.queryForm[key]) {
							arr.push({
								key: key,
								value: _this.queryForm[key]
							})
						}
					}
					params.search = arr
				}
				this.$http.post("/api/zc_list", params).then(res => {
					this.DataList = res.data.data;
					this.page = res.data.page
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				// console.log(val)
			},
			showDetails(row) {
				let _this = this
				_this.statusInfo = {}
				this.files = [];
				this.editItem(row)
				// this.getLogs(row.id)
				this.dialogTitle = '资产详情'
				this.dialogVisible = true
				this.disabled = true
				this.$http.post("/api/zc_zcinfo", {
					id: row.id
				}).then(res => {
					let rData = res.data
					_this.logList = rData.logs;
					if (rData.status != 0) {
						let info = rData.logs[0]
						switch (rData.status) {
							case 1:
								info.title = '领用信息'
								if (info.czdesc) {
									info.user_name = info.czdesc.substr(0, info.czdesc.indexOf('主动认领'))
								}
								break;
							case 2:
								info.title = '派发信息'
								if (info.czdesc) {
									info.user_name = info.czdesc.substr(info.czdesc.indexOf('：') + 1)
								}
								break;
							case 3:
								info.title = '借用信息'
								break;
							case 5:
								info.title = '维修中'
								break;
						}
						_this.statusInfo = info
					}
				})
			},
			getLogs(id) {
				this.$http.post("/api/zc_logs", {
					zc_id: id
				}).then(res => {
					// console.log(res.data)
					if (res.data.code == 200) {
						this.logList = res.data.data;
					}
				})
			},
			add() {
				let _this = this
				this.dialogTitle = '新增资产'
				this.dialogVisible = true
				this.disabled = false
				this.EditItem = {
					zc_date: _this.curDate,
					status: 0,
				}
				this.files = []
				this.EditItem = {
					..._this.EditItem
				}
				this.files = [..._this.files]
			},
			editItem(row) {
				let _this = this
				this.dialogTitle = '编辑资产'
				this.dialogVisible = true
				this.disabled = false
				this.EditItem = row
				let arr = [],
					cus_arr = []
				if (row.files != '' && row.files != null && row.files != undefined) {
					arr = JSON.parse(row.files)
					_this.files = arr
				}
				if (row.remark && row.remark != '') {
					_this.EditItem.zdjson = JSON.parse(row.remark)
				}
			},
			saveForm(formName) {
				let _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let params = {}
						params = {
							..._this.EditItem
						}
						if (!params.zc_typeid) {
							_this.$message.warning('请选择资产分类！')
							return;
						}
						if (!params.zc_storeid) {
							_this.$message.warning('请选择资产所在位置！')
							return;
						}
						if (!params.id) {
							params.autocode = 1
						}
						if (this.files.length > 0) {
							params.files = JSON.stringify(_this.files)
						}
						params.remark = JSON.stringify(params.zdjson)

						if (params.store_gly_id) {
							for (let gly of this.storeAdmin) {
								if (gly.union_id == params.store_gly_id) {
									params.store_gly_name = gly.name;
								}
							}

						}
						this.$http.post('/api/zc_save', params).then(res => {
							_this.dialogVisible = false;
							this.$message({
								type: 'success',
								message: '保存成功'
							});
							_this.getList()
						})
					} else {
						_this.$message.warning('请将必填项填写完整')
						console.log('error submit!!');
						return false;
					}
				});
			},
			removeItem(item) {
				let _this = this
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_delete", {
						id: item.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						_this.getList()
					})

				}).catch(() => {

				});

			},
			delSlct() {
				let _this = this
				if (_this.multipleSelection.length == 0) {
					_this.$message.warning('请选择至少一条资产')
				} else {
					_this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.$http.post("/api/zc_delete", {
							id: _this.multipleSelection.map(item => {
								return item.id
							}).join(',')
						}).then(res => {
							_this.$message.success('删除成功');
							_this.getList()
						})
					}).catch(() => {

					});
				}


			},
			paginate(val) {
				this.page.current_page = val
				this.getList();
			},
			handleSizeChange(val) {
				this.page.current_page = 1
				this.page.pre_page = val
				this.getList();
			},
			handleCascaderChange(e) {
				console.log(e)
			},
			handleCascaderChange1(e) {
				if (e) {
					let _this = this
					_this.EditItem.zc_typeid = e
					let a = _this.$refs['myCascader1'].getCheckedNodes()[0].data
					console.log(1114, a)
					_this.FieldItem = a
					_this.EditItem.ename = a.ename
					_this.EditItem.jldw = a.unit
					if (a.zdjson && a.zdjson != '') {
						_this.EditItem.zdjson = JSON.parse(a.zdjson)
					}
					_this.EditItem = {
						..._this.EditItem
					}
				}
			},
			handleCascaderChange2(e) {
				// console.log(e)
				if (e) {
					let _this = this
					_this.EditItem.zc_whereid = e
					let a = _this.$refs['myCascader2'].getCheckedNodes()[0].data
					let allCates = _this.getFathersById(e, _this.areaList, 'id', 'children', 'storename')
					allCates.pop();
					let ss = allCates.length > 0 ? `${allCates.join('/')}/${a.storename}` : a.storename
					_this.EditItem.zc_where = ss
					console.log(_this.EditItem.zc_where)
					_this.EditItem = {
						..._this.EditItem
					}
				}
			},
			/**
			 * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
			 * id:     匹配的值
			 * data: 匹配的数组数据
			 * prop: 匹配的字段名
			 * children: 子集label
			 * cname: 输出数组需要的字段名
			 */
			getFathersById(id, data, prop, children, cname) {
				var arrRes = [],
					arrText = []
				const rev = (data, nodeId) => {
					for (var i = 0, length = data.length; i < length; i++) {
						const node = data[i]
						if (node[prop] == nodeId) {
							arrRes.unshift(node[prop])
							arrText.unshift(node[prop])
							return true
						} else {
							if (node[children] && node[children].length) {
								if (rev(node[children], nodeId)) {
									arrRes.unshift(node[prop])
									arrText.unshift(node[cname])
									return true
								}
							}
						}
					}
					return false
				}
				rev(data, id)
				return arrText //返回中文集合
			},

			getUser(e) {
				console.log(e)
				let _this = this
				if (e && e.length > 0) {
					_this.EditItem.target_uid = e[0].union_id
					_this.EditItem.borrower_name = e[0].name
				}
				_this.showUserDialog = false
			},
			closeUser() {
				this.showUserDialog = false
			},

			addCusField() {
				console.log(this.FieldItem)
				console.log(this.EditItem)
				this.FieldItem.id = this.EditItem.zc_typeid
				for (let item of this.typeList) {
					for (let sitem of item.children) {
						if (sitem.id == this.FieldItem.id) {
							this.FieldItem = sitem;
							this.FieldItem.value = item.cname;
							break
						}
					}
					if (this.FieldItem.cname) {
						break
					}
				}
				let _this = this
				if (!this.FieldItem.id) {
					this.$message.warning('请先选择资产分类！')
					return;
				}
				this.showCusField = true
				console.log(1103, _this.FieldItem.zdjson)
				if (_this.FieldItem.zdjson && _this.FieldItem.zdjson != '' && _this.FieldItem.zdjson != 'null' && _this
					.FieldItem.zdjson != 'undefined') {
					_this.FieldItem.zdjson = JSON.parse(_this.FieldItem.zdjson)
				} else {
					_this.FieldItem.zdjson = []
				}
				_this.FieldItem = {
					..._this.FieldItem
				}
			},
			saveCusField() {
				let _this = this
				let objvalue = _this.EditItem.zdjson //保存后回显防止旧值被覆盖
				let params = {},
					obj = {}
				obj = JSON.parse(JSON.stringify(_this.FieldItem))
				params = {
					...obj
				}
				params.remark = ""
				params.zdjson = JSON.stringify(obj.zdjson)
				this.$http.post("/api/zc_type_save", params).then(res => {
					_this.showCusField = false
					_this.$message({
						type: 'success',
						message: '保存成功'
					});
					_this.getType()
					_this.EditItem.zdjson = JSON.parse(params.zdjson)
					for (let a in objvalue) {
						for (let b in _this.EditItem.zdjson) {
							if (a == b && objvalue[a] != '') {
								_this.EditItem.zdjson[b] = objvalue[a]
							}
						}
					}
					_this.EditItem = {
						..._this.EditItem
					}
				})
			},
			addCus() {
				let _this = this
				this.FieldItem.zdjson.push({})
				this.FieldItem = {
					..._this.FieldItem
				}
			},
			delCus(item, index) {
				let _this = this
				this.FieldItem.zdjson.splice(index, 1)
				this.FieldItem = {
					..._this.FieldItem
				}
			},
			createQrcode() {
				this.$confirm('确定生成所有二维码？此过程耗时较长！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_qrcode").then(res => {
						this.$message({
							type: 'success',
							message: '已成功生成二维码'
						});
						this.getList();
					})
				})
			},
			printZcList() {
				console.log(this.multipleSelection)

				this.bqZcList = this.multipleSelection
				if (this.bqZcList.length == 0) {
					this.$alert("请选择要打印的资产")
				} else {
					for (let item of this.bqZcList) {
						if (!item.qrcode) {
							this.$alert("请先生成好所有的二维码！")
							return
						}
					}
					this.showBiaoqian = true
				}
				//

			},
			print_ruku() {

				if (this.multipleSelection.length == 0) {
					this.$message.warning('请选择一条资产');
					return
				} else {
					console.log(this.multipleSelection)
					this.$http.post("/api/zc_rukudan_xls", {
						data: this.multipleSelection
					}).then(res => {
						window.open(res.data.url, "_blank")
					})
				}
			},
			getCurDate() {
				let d = new Date();
				let year = d.getFullYear(); //获取完整的年份(4位)
				let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let date = d.getDate(); //获取当前日(1-31)
				d.getDay(); //获取当前星期X(0-6,0代表星期天)
				d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
				d.getHours(); //获取当前小时数(0-23)
				d.getMinutes(); //获取当前分钟数(0-59)
				d.getSeconds(); //获取当前秒数(0-59)
				d.getMilliseconds(); //获取当前毫秒数(0-999)
				d.toLocaleDateString(); //获取当前日期
				let mytime = d.toLocaleTimeString(); //获取当前时间
				d.toLocaleString(); //获取日期与时间
				this.curDate = `${year}-${month}-${date}`
			},
			uploadAttch(e) {


				this.$http.post("/api/import_zc_xls", {
					url: e.src
				}).then(res => {
					if (res.data.errordata.length > 0) {
						this.ErrLog = res.data.errordata
						this.dialogErrorVisible = true;

					}
					this.getList();
					this.$message.success('导入成功');
				})
			},
			uploadXls() {
				document.getElementById('addXlsbtn').click()
			},
			showStore(item) {
				console.log(item)
				this.storeid = item.last_location.split(')')[0].replace('(', '')
				this.storename = item.last_location
				this.showStorewin = true
			}
		}
	}
</script>

<style lang="less" type="text/scss">

    .statusInfo-box{
        border: 1px solid #E4E7ED;
        margin: 20px 0 20px 30px;
        .el-form-item {
            margin-bottom: 0px;
            border-top: 1px solid #EBEEF5;
        }
        .el-form-item:first-of-type {
            border-top: none;
        }
        
    }
    .pagezclist {
        .labelCss {
            .el-form-item__label {
                color: #909399 !important;
                font-size: 12px;
            }
        }
        
        .el-input, .el-select, .el-cascader {
            width: 100% !important;
        }
        
        .el-dropdown {
            vertical-align: top;
        }
        
        .el-dropdown + .el-dropdown {
            margin-left: 15px;
        }
        
        .el-icon-arrow-down {
            font-size: 12px;
        }
        
        .cus_dropdown {
            .el-button--primary {
                color: #409EFF !important;
                background-color: #Ecf5ff !important;
                border-color: #b3d8ff !important;
            }
        }
        
        .el-divider {
            margin-top: 0;
        }
        
        .el-drawer__wrapper {
            top: 70px;
        }
        
        .drawer_box {
            padding: 10px;
            
            .el-form-item {
                margin-bottom: 0px;
            }
        }
		.updimage{
			width: 100px; height: 100px; display: inline-block; vertical-align: top; margin: 0 10px 10px 0; border: 1px dotted #ccc;
			background-size: 100% auto;
			background-position: center;
			overflow: hidden;
			background-repeat: no-repeat;
			cursor: pointer;
		}
        .cus_page {
            margin-top: 10px;
            .el-pagination__sizes {
                width: 130px;
            }
        }
    }
	
</style>
