<template>
	<div>
		<el-dialog title="打印标签" @close="closeDialog" :visible.sync="dialogVisible" width="1150px" custom-class="width_800 cus_dialog">
			<div>
				纸带类型：
				<el-radio-group v-model="PrintType" @change="changeImage">
				    <el-radio :label="1">50MM*70MM</el-radio>
				    <el-radio :label="2">50MM*30MM</el-radio>
				   
				  </el-radio-group>
			</div>
			<div style="padding-top: 350px;" v-if="PrintType==1">
				<div v-for="(img,idx) in ImageList" :key="idx" class="bqitem" style="background-color: #ffffff;">
					<img class="bqimg" :src="img" alt="" style="width: 100%;">
				</div>
			</div>
			<div style="" v-if="PrintType==2">
				<div v-for="(img,idx) in ImageList" :key="idx" class="bqitem2" style="background-color: #ffffff;">
					<img class="bqimg" :src="img" alt="" style="width: 100%;">
				</div>
			</div>

			<div slot="footer">
				<div style="display: inline-block; margin-right: 50px; color: orangered;" v-if="connecting">等待连接中</div>
				<div style="display: inline-block; margin-right: 50px; color: orangered;" v-if="printing">正在打印 {{printidx}}/{{ImageList.length}}</div>
				<el-button v-if="printing" icon="el-icon-s-release" type="primary" style="margin-right: 20px;" @click="endPrint">取消打印</el-button>
				 <el-button icon="el-icon-printer" type="primary" @click="startPrint">打印</el-button>
			</div>
		</el-dialog>
		<div>
			<canvas id="canvas" width="384" height="533" style="background-color: #A9A9A9;"></canvas>
			<canvas id="canvas2" width="400" height="240" style="background-color: #A9A9A9;"></canvas>
		</div>
	</div>
</template>
<script>
	let printer = {
		cvs: null,
		p: null,
		logo: null
	}
	var loadScript = function(url, callback) {
		var script = document.createElement("script");
		script.type = "text/javascript";
		if (script.readyState) { //IE
			script.onreadystatechange = function() {
				if (script.readyState == "loaded" ||
					script.readyState == "complete") {
					script.onreadystatechange = null;
					callback();
				}
			};
		} else { //Others: Firefox, Safari, Chrome, and Opera
			script.onload = function() {
				callback();
			};
		}
		script.onerror = function(e) {}
		script.src = url;
		window.document.body.appendChild(script);
	};

	window.loadScript = loadScript;
	



	export default {
		props: ['zclist'],
		data() {
			return {
				DataList: [],
				ImageList: [],
				dialogVisible: true,
				printidx: 1,
				printing: false,
				bCanclePrint: false,
				PrintType:1,
				connecting:false
			}
		},
		mounted() {
			if (this.zclist) {
				this.DataList = this.zclist;
				this.dialogVisible = true;
				this.initCanvas()
			}

			if (!window.g_websocket) {
				window.printer_connected=false;
				window.noconnect=function(){
					alert("未连接标签打印机")
				}
				window.loadScript("./static/jcPrinterSdk_api.js", e => {
					getInstance();
				})


			}
		},
		methods: {
			changeImage(){
				this.ImageList=[]
				if(this.PrintType==1){
					printer.cvs = document.getElementById("canvas");
					printer.context = printer.cvs.getContext("2d");
					this.drawBiaoqian1()
				}else{
					printer.cvs = document.getElementById("canvas2");
					printer.context = printer.cvs.getContext("2d");
					this.drawBiaoqian2()
				}
			},
			closeDialog() {
				this.$parent.showBiaoqian = false
			},
			initCanvas() {


				var img = new Image();
				img.onload = () => {

					printer.logo = img;
					
					
					if(this.PrintType==1){
						printer.cvs = document.getElementById("canvas");
						printer.context = printer.cvs.getContext("2d");
						this.drawBiaoqian1()
					}else{
						printer.cvs = document.getElementById("canvas2");
						printer.context = printer.cvs.getContext("2d");
						this.drawBiaoqian2()
					}
				}
				img.onerror = e => {
					console.log(e)
				}
				img.src = "./static/images/schlogo.png";
			},
			drawBiaoqian1() {

				let drawBQ = (idx) => {
					if (idx < this.DataList.length) {
						printer.context.clearRect(0,0,384,534)
						let arr = [this.DataList[idx].title];
						arr.push(this.DataList[idx].zc_code);
						arr.push(this.DataList[idx].pinpai + this.DataList[idx].xinghao)
						arr.push(this.DataList[idx].zc_date);
						let qrcode_url = this.DataList[idx].qrcode;
						console.log(qrcode_url)
						var img = new Image();
						img.onload = () => {
							console.log(qrcode_url)
							var context = printer.context
							context.drawImage(img, 0, 0, 384, 533, 0, 0, 384, 534);
							context.save();
							context.translate(384, 0);
							context.rotate(90 * Math.PI / 180);
							context.font = "24px bold 黑体";
							// 设置颜色
							context.fillStyle = "#000";
							// 设置水平对齐方式

							// 绘制文字（参数：要写的字，x坐标，y坐标）
							context.fillText("常熟高新园中专", 40, 66);
							if (arr && arr.length > 0) {
								arr[0] = "资产名称：" + arr[0]
							} else {
								arr = []
								arr[0] = "资产名称："
							}
							if (arr.length > 1) {
								arr[1] = "资产编码：" + arr[1]
							} else {
								arr[1] = "资产编码："
							}
							if (arr.length > 2) {
								arr[2] = "品牌型号：" + arr[2]
							} else {
								arr[2] = "品牌型号："
							}
							if (arr.length > 3) {
								arr[3] = "入库日期：" + arr[3]
							} else {
								arr[3] = "入库日期："
							}
							context.fillText(arr[0], 40, 135);
							context.fillText(arr[1], 40, 202);
							context.fillText(arr[2], 40, 265);
							context.fillText(arr[3], 40, 330);
							var qrcode = new Image();
							qrcode.onload = () => {
								context.drawImage(qrcode, 0, 0, qrcode.width, qrcode.height, 365, 25, 130, 130);
								context.drawImage(printer.logo, 0, 0, 430, 430, 365, 25, 130, 130);
								let base64 = printer.cvs.toDataURL('image/png') //.replace('data:image/png;base64,', '');
								this.ImageList.push(base64)
								
								context.restore()
								drawBQ(idx + 1)
							}
							qrcode.src = qrcode_url;


						}
						img.src = "./static/images/biaoqian1.jpg"
					} else {
						console.log("ok")
					}
				}
				drawBQ(0)
			},
			drawBiaoqian2() {
			
				let drawBQ = (idx) => {
					if (idx < this.DataList.length) {
						printer.context.clearRect(0,0,400,240)
						let arr = [this.DataList[idx].title];
						arr.push(this.DataList[idx].pinpai + this.DataList[idx].xinghao)
						arr.push(this.DataList[idx].zc_code);
						
						arr.push(this.DataList[idx].zc_date);
						let qrcode_url = this.DataList[idx].qrcode;
						console.log(qrcode_url)
						var img = new Image();
						img.onload = () => {
							console.log(qrcode_url)
							var context = printer.context
							context.drawImage(img, 0, 0, 400, 240, 0, 0, 400, 240);
							context.save();
							
							context.font = "18px bolder 黑体";
							// 设置颜色
							context.fillStyle = "#000";
							// 设置水平对齐方式
			
							// 绘制文字（参数：要写的字，x坐标，y坐标）
							context.fillText("常熟高新园中专", 20, 36);
							if (arr && arr.length > 0) {
								arr[0] = "资产名称：" + arr[0]
							} else {
								arr = []
								arr[0] = "资产名称："
							}
							if (arr.length > 1) {
								arr[1] = "品牌型号：" + arr[1]
							} else {
								arr[1] = "品牌型号："
							}
							if (arr.length > 2) {
								arr[2] = "资产编码：" + arr[2]
							} else {
								arr[2] = "资产编码："
							}
							if (arr.length > 3) {
								arr[3] = "入库日期：" + arr[3]
							} else {
								arr[3] = "入库日期："
							}
							context.fillText(arr[0], 20, 83);
							context.fillText(arr[1], 20, 127);
							context.fillText(arr[2], 20, 172);
							context.fillText(arr[3], 20, 215);
							var qrcode = new Image();
							qrcode.onload = () => {
								context.drawImage(qrcode, 0, 0, qrcode.width, qrcode.height, 250, 8, 130, 130);
								context.drawImage(printer.logo, 0, 0, 430, 430, 250, 8, 130, 130);
								let base64 = printer.cvs.toDataURL('image/png') //.replace('data:image/png;base64,', '');
								this.ImageList.push(base64)
								
								context.restore()
								drawBQ(idx + 1)
							}
							qrcode.src = qrcode_url;
			
			
						}
						img.src = "./static/images/biaoqian2.jpg"
					} else {
						console.log("ok")
					}
				}
				drawBQ(0)
			},
			startPrint() {
				this.printidx=1;
				this.getConnect();
			},
			getConnect() {
				this.connecting=true
				getConnectPrinter((data) => {
					
					var arrParse = JSON.parse(JSON.stringify(data));
					
					let t = setInterval(_=>{
						if(window.printer_connected){
							clearInterval(t)
							this.connecting=false
							this.printImages()
						}
					},300)
					
				});
			},
			printImages() {
				this.picturePrintA(0)

			},

			picturePrintA(idx) { //打印图像
				//console.log("开始打印了")
				let that = this;
				var content = 3; //打印浓度
				var paperType = 1; //间隙纸
				var num = 1;
				if(idx>=this.ImageList.length||that.bCanclePrint){
					endJob(function(data) {
						var arrParse = JSON.parse(JSON.stringify(data));
						if (String(arrParse.resultAck.info).indexOf("endJob ok") > -1) {
							//document.getElementById("printId").disabled = false;
						}
						that.printing = false;
					});
					
					
					
					
					return
				}
				let base64 = this.ImageList[idx].replace('data:image/png;base64,', '');


				var height = 70;
				var width = 50;
				if(that.PrintType==1){
					height=70;
					width=50;
				}
				else if(that.PrintType==2){
					height=30;
					width=50;
					content=10;
				}
				//console.log("kaishidayin")
				startJob(parseInt(width), parseInt(height), 0, 1, function(data) {

					var arrParse = JSON.parse(JSON.stringify(data));
					console.log('startJob')
					console.log(arrParse)
				});

				


				var topA = 0;
				var leftA = 0;
				var bottomA = 0;
				var rightA = 0;
				var x = 0;
				var y = 0;
				that.printing = true;
				that.printidx=idx+1;
				console.log('当前打印:'+idx)
				commitJob(parseInt(num), parseInt(content), parseInt(paperType), base64, parseInt(x), parseInt(y), 0, 0, 0, +topA,
					+
					leftA, +bottomA, +rightA,
					function(data) {
						var arrParse = JSON.parse(JSON.stringify(data));
						console.log('commitJob');
						console.log(arrParse);
						setTimeout(()=>{
							that.picturePrintA(idx+1)
						},1200)
					});

				////console.log(str);

			},
			endPrint(){
				this.bCanclePrint = true
			}
		}
	}
</script>
<style>
	.bqitem {
		display: inline-block;
		width: 200px;
		margin-right: 70px;
		margin-top: -120px;
		margin-bottom: 50px;
		transform: rotateZ(-90deg);
		transform-origin: left top;
	}
	.bqitem2 {
		display: inline-block;
		width: 200px;
		margin-right: 70px;
		margin-bottom: 50px;
		
	}
</style>
