<template>
	<div>
		<el-dialog :title="title" @close="closeDialog" :visible.sync="dialogVisible" width="1150px" custom-class="width_800 cus_dialog">
			<el-table :data="DataList"
			    style="width: 100%;"  stripe border size="small">
			   <el-table-column type="index" label="编号" width="60">
			   </el-table-column>
			   <el-table-column prop="status" label="当前状态" width="80">
			   	<template slot-scope="scope">
			   		<span v-if="scope.row.status==0" class="st0">闲置</span>
			   		<span v-if="scope.row.status==1" class="st1">领用</span>
			   		<span v-if="scope.row.status==2" class="st2">派发</span>
			   		<span v-if="scope.row.status==3" class="st3">借用</span>
			   		<span v-if="scope.row.status==4" class="st4">租赁已退还</span>
			   		<span v-if="scope.row.status==5" class="st5">维修中</span>
			   		<span v-if="scope.row.status==-1" class="st-1">报废</span>
			   	</template>
			   </el-table-column>
			   <el-table-column prop="zc_code" label="资产编码" width="170">
			   	<template slot-scope="scope">
			   		<div>{{scope.row.zc_code}}</div>
			   	</template>
			   </el-table-column>
			   <el-table-column prop="title" label="资产名称" min-width="120"></el-table-column>
			   <el-table-column prop="zc_typename" label="资产分类" min-width="100">
				   <template slot-scope="scope">
				   	{{scope.row.zc_typeparent}} {{scope.row.typename}}
				   </template>
			   </el-table-column>
			   <el-table-column prop="storename" label="所在位置" width="180">
				   <template slot-scope="scope">
				   	<div >
				   		<div v-if="scope.row.last_location">
				   			{{scope.row.last_location}}
				   		</div>
				   		<div v-else>
				   			<span v-if="scope.row.storename3">{{scope.row.storename3}}</span>
				   			<span v-if="scope.row.storename2">{{scope.row.storename2}}</span>
				   			<span v-if="scope.row.storename1">{{scope.row.storename1}}</span>
				   		</div>
				   	</div>
				   </template>
			   </el-table-column>
			   <el-table-column prop="pinpai" label="品牌" width="120"></el-table-column>
			   <el-table-column prop="xinghao" label="型号" width="120"></el-table-column>
			   <el-table-column prop="jine" label="价格" width="100" :show-overflow-tooltip="true">
			   	<template slot-scope="scope">
			   		<span>￥{{scope.row.jine}}</span>
			   	</template>
			   </el-table-column>
			   <el-table-column prop="last_username" label="使用人" width="100" :show-overflow-tooltip="true">
			   </el-table-column>
			  <!-- <el-table-column prop="status" label="操作" width="100">
			   	<template slot-scope="scope">
			   		<el-button size="small" type="infor" v-if="scope.row.status==1||scope.row.status==2||scope.row.status==3">归还</el-button>
			   	</template>
			   </el-table-column> -->
			</el-table>
            <div slot="footer">
                <el-button type="primary" @click="closeDialog">关闭</el-button>
            </div>
		</el-dialog>
		
	</div>
</template>
<script>
	
	export default {
		props: ['storeid','title'],
		data() {
			return {
				userinfo: {
					union_id: ""
				},
				DataList: [],
				
				dialogVisible: true
			}
		},
		mounted() {
			console.log(123)
			this.userinfo = JSON.parse(localStorage.getItem("user"))
			if (this.storeid) {
				this.getList()
			}
		},
		methods: {
			closeDialog() {
				this.$parent.showStorewin  = false
			},
			getList(){
				
					
					this.$http.post("/api/zc_list", {
						ktype: 'zc_storeid',
						keyword: this.storeid,
						page: 1,
						pagesize: 500
					}).then(res => {
						
						this.DataList = res.data.data;
					})
					
				
			}
			
			
		}
	}
</script>
<style>
	.bqitem {
		display: inline-block;
		width: 200px;
		margin-right: 70px;
		margin-top: -120px;
		margin-bottom: 50px;
		transform: rotateZ(-90deg);
		transform-origin: left top;
	}
</style>
